import { Fragment, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { Drawer, IconButton, SwipeableDrawer } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ArrowBack, ArrowForward, Edit, Feed } from '@mui/icons-material';
import AdaptiveAppBar from './AdaptiveAppBar';
import Footer from './Footer';
import Toolbar from './Toolbar';
import Canvas from './Canvas';
import Help from './Help';
import Sidebar from './Sidebar';

const Workspace = styled.div`
    display: flex;
    height: calc(100vh - 150px);
    width: calc(100vw - 300px);
    padding: 20px;
    @media (max-width: 899px) {
        padding: 84px 20px 20px 20px;
        height: calc(100dvh - 124px);
        width: auto;
    }
`;

const Main = ({ theme, isDesktop }) => {
    const { pathname } = useLocation();
    const skipIntroCookieName = 'SKIP_INTRO_' + (isDesktop ? 'D' : 'M');
    const [showIntro, setShowIntro] = useState(pathname === '/' && Cookies.get(skipIntroCookieName) !== '1');

    const [newScreenColour, setNewScreenColour] = useState('#d06cb2');

    const [colours, setColours] = useState([
        '#f5e619',
        '#a0db43',
        '#40bfb8',
        '#518cd8',
        '#e84862',
        '#eb4a19',
        '#f4a900',
    ]);

    const [screens, setScreens] = useState([
        {
            length: "27",
            width: "16",
            height: "9",
            colour: '#2aa2da'
        },
        {
            length: "29",
            width: "21",
            height: "9",
            colour: '#5fc869'
        }
    ]);

    const [toolbarOpen, setToolbarOpen] = useState(false);
    const [newsBarOpen, setNewsBarOpen] = useState(false);

    const AdaptiveToolbarContainer = isDesktop ? Fragment : SwipeableDrawer;

    const AdaptiveNewsBarContainer = isDesktop ? Drawer : SwipeableDrawer;

    const adaptiveToolbarProps = isDesktop ? {} : {
        open: toolbarOpen,
        onClose: () => setToolbarOpen(false),
        onOpen: () => setToolbarOpen(true),
        elevation: 2,
        PaperProps: { sx: { height: 'calc(100% - 64px)', top: 64 } },
        BackdropProps: { style: { background: 'transparent' } }
    };

    const adaptiveNewsBarProps = isDesktop ? {
        open: true,
        variant: 'persistent'
    } : {
        open: newsBarOpen,
        onClose: () => setNewsBarOpen(false),
        onOpen: () => setNewsBarOpen(true),
        PaperProps: { sx: { height: 'calc(100% - 64px)', top: 64 } },
        BackdropProps: { style: { background: 'transparent' } }
    };

    const randomColour = () => {
        const coloursCopy = [...colours];
        const randomColour = coloursCopy.splice(Math.floor(Math.random() * coloursCopy.length), 1)[0];
        setColours(coloursCopy);
        return randomColour;
    }

    const addColourBack = colour => setColours([...colours, colour]);

    return (
        <ThemeProvider theme={theme}>
            <AdaptiveAppBar isDesktop={isDesktop} content={!isDesktop ?
                <><IconButton onClick={() => setToolbarOpen(true)} size='large' sx={{ position: 'fixed', top: 8, left: 8 }}>
                    {toolbarOpen ? <ArrowBack size='large' /> : <Edit size='large' />}
                </IconButton>
                <IconButton onClick={() => setNewsBarOpen(true)} size='large' sx={{ position: 'fixed', top: 8, right: 8 }}>
                    {newsBarOpen ? <ArrowForward size='large' /> : <Feed size='large' />}
                </IconButton></> : <></>}
            />
            <Workspace>
                <AdaptiveToolbarContainer {...adaptiveToolbarProps} >
                    <Toolbar
                        isDesktop={isDesktop}
                        toolbarOpen={toolbarOpen}
                        newScreenColour={newScreenColour}
                        setNewScreenColour={setNewScreenColour}
                        randomColour={randomColour}
                        addColourBack={addColourBack}
                        screens={screens}
                        setScreens={setScreens}
                        close={() => setToolbarOpen(false)}
                    />
                </AdaptiveToolbarContainer>
                <Canvas screens={screens} />
            </Workspace>
            <AdaptiveNewsBarContainer elevation={2} anchor='right' {...adaptiveNewsBarProps}>
                <Sidebar />
            </AdaptiveNewsBarContainer>
            <Footer />
            { showIntro && <Help isDesktop={isDesktop} onClose={() => setShowIntro(false)} /> }
            <Outlet />
        </ThemeProvider>
    );
}

Main.propTypes = {
    theme: PropTypes.object,
	isDesktop: PropTypes.bool
};

export default Main;