import styled from 'styled-components';
import PropTypes from 'prop-types';

const ScreenContainer = styled.div`
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    position: absolute;
    bottom: ${props => props.verticalOffset - 3}px;
    left: ${props => props.horizontalOffset - 3}px;
    border-style: solid;
    color: ${props => props.colour}; 
    background-color: ${props => props.colour}64; 
`;

const Screen = ({ height, width, verticalOffset, horizontalOffset, colour }) => {
	return (
		<ScreenContainer
			data-testid='ScreenContainer'
			height={height}
			width={width}
			verticalOffset={verticalOffset}
			horizontalOffset={horizontalOffset}
			colour={colour}
		/>
	);
}

Screen.propTypes = {
	height: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired,
	verticalOffset: PropTypes.number.isRequired,
	horizontalOffset: PropTypes.number.isRequired,
	colour: PropTypes.string.isRequired
};

export default Screen;