import { createRef, useRef, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Collapse } from '@mui/material';
import ScreenSpec from './ScreenSpec';
import AddScreen from './AddScreen';

const ToolbarContainer = styled.div`
    padding: 10px;
    background-color: white;
    overflow-y: auto;
    scrollbar-width: thin;
    height: calc(100dvh - 160px);
    width: calc(100vw - 18px);

    @media (min-width: 900px) {
        border-radius: 22px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        float: left;
        height: auto;
        width: auto;
        min-width: 450px;
        margin-right: 20px;
    }

    &::-webkit-scrollbar {
        width: 12px;
    }
`;

const Toolbar = ({isDesktop, toolbarOpen, newScreenColour, setNewScreenColour, randomColour, addColourBack, screens, setScreens, close}) => {
    const refs = useRef([]);

    refs.current = screens.map((screen, index) => refs.current[index] = createRef());

    const [invalidScreens, setInvalidScreens] = useState(screens.map(() => false));

    const updateScreen = (index, updateFunction) => setScreens(screens.map((screen, i) => {
        if (index === i) {
            updateFunction(screen);
        }
        return screen;
    }));

    const updateLength = index => length => updateScreen(index, screen => screen.length = length);

    const updateWidth = index => width => updateScreen(index, screen => screen.width = width);

    const updateHeight = index => height => updateScreen(index, screen => screen.height = height);

    const rotateScreen = index => () => updateScreen(index, screen => {
        const height = screen.height;
        screen.height = screen.width;
        screen.width = height;
    });

    const addScreen = colour => () => {
        screens.length < 9 && setNewScreenColour(randomColour());
        setScreens([
            ...screens,
            {
                length: "27",
                width: "16",
                height: "9",
                colour
            }
        ])
    }

    const removeScreen = index => () => {
        const inputs = refs.current.filter((input, i) => index !== i).map(ref => ref.current.inputs);
        refs.current.slice(0, -1).forEach((ref, i) => ref.current.setInputs(inputs[i]));
        (screens.length < 10 ? addColourBack : setNewScreenColour)(screens.find((screen, i) => index === i).colour);
        setScreens(screens.filter((screen, i) => index !== i));
    }

    const applyChanges = () => {
        refs.current.forEach(ref => ref.current.setScreenParameters());
        close();
    };

    const setInvalidScreen = index => newInvalid => setInvalidScreens(screens.map((screen, i) => i === index ? newInvalid : invalidScreens[i]));

    return (
        <>
            <ToolbarContainer>
                <TransitionGroup>
                    {screens.map((screen, index) => <Collapse key={index}><ScreenSpec
                        key={index}
                        ref={refs.current[index]}
                        isDesktop={isDesktop}
                        toolbarOpen={toolbarOpen}
                        {...screen}
                        setLength={updateLength(index)}
                        setWidth={updateWidth(index)}
                        setHeight={updateHeight(index)}
                        rotateScreen={rotateScreen(index)}
                        removeScreen={removeScreen(index)}
                        setInvalidScreen={setInvalidScreen(index)} /></Collapse>)}
                </TransitionGroup>
                {screens.length < 10 && <AddScreen colour={newScreenColour} addScreen={addScreen} />}
            </ToolbarContainer>
            {!isDesktop && <Button
                onClick={applyChanges}
                variant="contained"
                sx={{ height: 48, width: 'calc(100% - 28px)', margin: '4px 14px', borderRadius: 2, position: 'absolute', bottom: 10 }}
                disabled={invalidScreens.some(screenInvalid => screenInvalid)}
                disableElevation>
                    Apply
                </Button>}
        </>
    );
}

Toolbar.propTypes = {
    isDesktop: PropTypes.bool,
    toolbarOpen: PropTypes.bool,
    newScreenColour: PropTypes.string,
    setNewScreenColour: PropTypes.func,
    randomColour: PropTypes.func,
    addColourBack: PropTypes.func,
    screens: PropTypes.arrayOf(PropTypes.shape({
        length: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
        colour: PropTypes.string.isRequired
    })),
    setScreens: PropTypes.func,
    close: PropTypes.func
};

export default Toolbar;