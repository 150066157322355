import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Input from '@mui/material/Input';
import { isValidNumber } from '../utils/validation-utils';

const InputField = ({value, textAlign, handleChange}) => {
    const [inputWidth, setInputWidth] = useState(18);

    useEffect(() => {
        setInputWidth(value.length * 9);
    }, [value])

    const handleChangeNumbersOnly = value => {
        if (value === '' || isValidNumber(value)) {
            handleChange(value);
        }
    }

    return (<Input
        value={value}
        onChange={e => handleChangeNumbersOnly(e.target.value)}
        size='small'
        inputProps={{ style: { padding: 0, textAlign } }}
        sx={{ width: inputWidth, minWidth: 9, maxWidth: 72 }}
    />);
}

InputField.propTypes = {
    value: PropTypes.string,
    textAlign: PropTypes.string,
    handleChange: PropTypes.func,
};

export default InputField;