import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Help from './components/Help';
import Info from './components/info/Info';
import InfoList from './components/info/InfoList';
import Main from './components/Main';
import Privacy from './components/Privacy';
import Terms from './components/Terms';

const App = () => {
    const theme = createTheme({
        typography: {
            fontFamily: [
                'Questrial',
                'sans-serif',
            ].join(','),
        },
    });

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Main theme={theme} isDesktop={isDesktop} />}>
                    <Route path='privacy' element={<Privacy />} />
                    <Route path='terms' element={<Terms />} />
                    <Route path='help' element={<Help isDesktop={isDesktop} />} />
                </Route>
                <Route path='/info' element={<InfoList theme={theme} isDesktop={isDesktop} />} />
                <Route path='/info/:id' element={<Info theme={theme} isDesktop={isDesktop} />} />
                <Route path='*' element={<Navigate to='/' replace />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
