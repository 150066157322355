import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, IconButton, Tooltip } from '@mui/material';
import { CancelRounded, ChangeCircleRounded, CheckCircleRounded } from '@mui/icons-material';
import InputField from './InputField';
import Dropdown from './Dropdown';
import Unit from '../utils/unit';
import popularAspectRatios from '../properties/popular-aspect-ratios.json'

const ScreenSpecContainer = styled.div`
    display: flex;
    min-height: 64px;
    margin: 4px;
    padding: 0 18px;
    border-radius: 8px;
    border-style: solid;
    justify-content: space-between;
    align-items:center;
    border-color: ${props => props.colour}; 
    background-color: ${props => props.colour + '64'}; 
`;

const ButtonContainer = styled.div`
    display: flex;
`;

const InputContainer = styled.td`
    line-height: 1.5;
`;

const ScreenSpec = forwardRef(({isDesktop, toolbarOpen, length, width, height, colour, setLength, setWidth, setHeight, rotateScreen, removeScreen, setInvalidScreen}, ref) => {
    useImperativeHandle(ref, () => ({ inputs: {lengthInput, widthInput, heightInput, lengthUnit}, setInputs, setScreenParameters }));

    const [anchorEl, setAnchorEl] = useState(null);
    const [toolbarTimer, setToolbarTimer] = useState(null);
    const [toolbarFullyOpened, setToolbarFullyOpened] = useState(false);
    const [lengthInput, setLengthInput] = useState(length);
    const [widthInput, setWidthInput] = useState(width);
    const [heightInput, setHeightInput] = useState(height);
    const [lengthUnit, setLengthUnit] = useState(Unit.INCH);

    useEffect(() => {
        if (toolbarOpen) {
            setToolbarTimer(setTimeout(() => setToolbarFullyOpened(true), 250));
        } else {
            clearTimeout(toolbarTimer);
            setToolbarFullyOpened(false);
        }
    }, [toolbarOpen]);

    useEffect(() => setInvalidScreen(!(Number(lengthInput) && Number(widthInput) && Number(heightInput))), [lengthInput, widthInput, heightInput]);

    const isInvalid = !(Number(lengthInput) && Number(widthInput) && Number(heightInput));

    const errorMessage = () => {
        const errors = [];
        if (!Number(lengthInput)) {
            errors.push('length');
        }
        if (!Number(widthInput) || !Number(heightInput)) {
            errors.push('aspect ratio');
        }
        return `Invalid ${errors.join('\nand ')}`;
    }

    const toggleLengthUnit = () => {
        if (lengthUnit === Unit.INCH) {
            setLengthUnit(Unit.CM);
            setLengthInput(lengthInput && (lengthInput * 2.54).toString());
        } else {
            setLengthUnit(Unit.INCH);
            setLengthInput(lengthInput && (lengthInput / 2.54).toString());
        }
    }

    const setInputs = ({lengthInput, widthInput, heightInput, lengthUnit}) => {
        setLengthInput(lengthInput);
        setWidthInput(widthInput);
        setHeightInput(heightInput);
        setLengthUnit(lengthUnit);
    }

    const setWidthAndHeightInputs = (width, height) => {
        setWidthInput(width);
        setHeightInput(height);
        setAnchorEl(null);
    }

    const flipWidthAndHeight = () => {
        setHeightInput(width);
        setWidthInput(height);
        rotateScreen();
    }

    const setScreenParameters = () => {
        setLength(lengthUnit === Unit.INCH ? lengthInput : (lengthInput / 2.54).toString());
        setWidth(widthInput);
        setHeight(heightInput);
    }

    return (
        <ScreenSpecContainer data-testid='ScreenSpecContainer' colour={colour}>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td>Length (diagonal):</td>
                            <InputContainer>
                                <InputField value={lengthInput} handleChange={setLengthInput} textAlign='right' />
                                <Button
                                    data-testid='LengthUnitButton'
                                    color='info'
                                    size='small'
                                    variant='outlined'
                                    sx={{ fontSize: 8, marginLeft: 1 }}
                                    onClick={toggleLengthUnit}
                                    disableElevation
                                >
                                    {lengthUnit}
                                </Button>
                            </InputContainer>
                        </tr>
                        <tr>
                            <td>Aspect ratio:</td>
                            <InputContainer>
                                <InputField value={widthInput} handleChange={setWidthInput} textAlign='right' /> : <InputField value={heightInput} handleChange={setHeightInput} textAlign='left' />
                                <Button
                                    data-testid='PopularButton'
                                    color='info'
                                    size='small'
                                    variant='contained'
                                    sx={{ fontSize: 8, marginLeft: 1 }}
                                    onClick={event => setAnchorEl(event.currentTarget)}
                                    disableElevation
                                >
                                    POPULAR
                                </Button>
                                <Dropdown
                                    anchorEl={anchorEl}
                                    handleClose={() => setAnchorEl(null)}
                                    items={popularAspectRatios.map(aspectRatio => ({
                                        text: `${aspectRatio.width} : ${aspectRatio.height}`,
                                        handleClick: () => setWidthAndHeightInputs(aspectRatio.width, aspectRatio.height)
                                    }))}
                                />
                            </InputContainer>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ButtonContainer>
                <Tooltip
                    open={isInvalid && (isDesktop || toolbarFullyOpened)}
                    title={<div data-testid='ErrorMessageText' style={{ whiteSpace: 'pre-line' }}>{errorMessage()}</div>}
                    placement='left'
                    TransitionProps={{ exit: false }}
                >
                    {isDesktop ?
                        <IconButton color='success' onClick={setScreenParameters} disabled={isInvalid}>
                            <CheckCircleRounded />
                        </IconButton> :
                        <div></div>
                    }
                </Tooltip>
                <IconButton color='primary' onClick={flipWidthAndHeight}>
                    <ChangeCircleRounded />
                </IconButton>
                <IconButton color='error' onClick={removeScreen}>
                    <CancelRounded />
                </IconButton>
            </ButtonContainer>
        </ScreenSpecContainer>
    );
});

ScreenSpec.propTypes = {
    isDesktop: PropTypes.bool,
    toolbarOpen: PropTypes.bool,
    length: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    colour: PropTypes.string.isRequired,
    setLength: PropTypes.func.isRequired,
    setWidth: PropTypes.func.isRequired,
    setHeight: PropTypes.func.isRequired,
    rotateScreen: PropTypes.func.isRequired,
    removeScreen: PropTypes.func.isRequired,
    setInvalidScreen: PropTypes.func.isRequired
};

export default ScreenSpec;