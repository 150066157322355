import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const Dropdown = ({anchorEl, handleClose, items}) => (
    <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
        sx={{ marginTop: 0.5, fontSize: 8 }}
    >
        {items.map(({handleClick, text}, index) => <MenuItem key={index} sx={{ fontSize: 13, paddingX: 1.75, paddingY: 0.25 }} onClick={handleClick}>
            {text}
        </MenuItem>)}
    </Menu>
);

Dropdown.propTypes = {
    anchorEl: PropTypes.object,
    handleClose: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        handleClick: PropTypes.func,
    })),
};

export default Dropdown;