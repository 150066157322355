import { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { AppBar } from '@mui/material';
import Header from '../images/header.png';

const HeaderContainer = styled.img`
    height: 52px;
    justify-content: center;
    padding: 32px 32px 6px 32px;
    display: block;
    @media (max-width: 899px) {
        height: 32px;
        padding: 16px 0 0 0;
        margin-left: auto;
        margin-right: auto;
    }
`;

const AdaptiveAppBar = ({ isDesktop, content }) => {
    const AdaptiveAppBarContainer = isDesktop ? Fragment : AppBar;

    const adaptiveAppBarProps = isDesktop ? {} : {
        color: 'transparent',
        elevation: 0,
        sx: { height: 64, bgcolor: '#ffffffaa' }
    };

	return (
		<AdaptiveAppBarContainer {...adaptiveAppBarProps}>
            {content}
            <HeaderContainer src={Header} alt='Display Compare' />
        </AdaptiveAppBarContainer>
	);
}

AdaptiveAppBar.propTypes = {
	isDesktop: PropTypes.bool,
    content: PropTypes.object
};

export default AdaptiveAppBar;