import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
    background-image: linear-gradient(to top, white, transparent);
    position: absolute;
    bottom: 0;
    height: 20px;
    width: 100%;
    font-size: 12px;
    text-align: center;

    > * {
        margin: 0 8px;
    }
`;

const StyledLink = styled(Link)`
    color: #1976d2;
    text-decoration: none;
`;

const Footer = () => {
	return (
        <FooterContainer data-testid='FooterContainer' >
            <span>© 2023 Display Compare</span>
            <StyledLink to='/privacy'>Privacy</StyledLink>
            <StyledLink to='/terms'>Terms</StyledLink>
            <StyledLink to='/help'>Help</StyledLink>
        </FooterContainer>
	);
}

export default Footer;