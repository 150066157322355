import configuration from "../properties/configuration.json"

export const doGetRequest = (path, setComplete, onSuccess, setError) => {
    setComplete(false);
    setError(null);
    fetch(configuration.INFO_URL + path)
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status);
            }
            return response.json();
        })
        .then(
            result => {
                onSuccess(result);
                setComplete(true);
            },
            error => {
                setError(error);
                setComplete(true);
            }
        );
}