import { createRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Screen from './Screen';

const CanvasContainer = styled.div`
    position: relative;
    width: 100%;
`;

const Canvas = ({screens}) => {
    const ref = createRef();
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [canvasWidth, setCanvasWidth] = useState(0);
    
    const handleResize = () => {
        if (ref.current) {
            const { width, height } = ref.current.getBoundingClientRect();
            setCanvasHeight(Math.round(height));
            setCanvasWidth(Math.round(width));
        }
    }

    const area = screen => screen.height * screen.width;

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize)
        return _ => window.removeEventListener('resize', handleResize);
    }, [ref]);

    const realScreens = screens.filter(screen => screen.length && screen.width && screen.height)
        .map(({length, width, height, colour}) => {
            const aspectRatio = width / height;
            const realHeight = length / Math.sqrt(Math.pow(aspectRatio, 2) + 1);

            return {
                height: realHeight,
                width: aspectRatio * realHeight,
                colour
            };
        });

    const maxScreenHeight = Math.max(...realScreens.map(screen => screen.height));
    const maxScreenWidth = Math.max(...realScreens.map(screen => screen.width));

    const canvasAspectRatio = canvasWidth / canvasHeight;
    const maxDimensionsAspectRatio = maxScreenWidth / maxScreenHeight;

    const pixelsPerInch = canvasAspectRatio > maxDimensionsAspectRatio ? (canvasHeight - 46) / maxScreenHeight : (canvasWidth - 46) / maxScreenWidth;

    const verticalOffset = (canvasHeight - maxScreenHeight * pixelsPerInch) / 2;
    const horizontalOffset = (canvasWidth - maxScreenWidth * pixelsPerInch) / 2;

    return (
        <CanvasContainer ref={ref}>
            {realScreens.sort((a, b) => area(b) - area(a)).map((screen, index) => <Screen
                key={index}
                height={screen.height * pixelsPerInch}
                width={screen.width * pixelsPerInch}
                verticalOffset={verticalOffset}
                horizontalOffset={horizontalOffset}
                colour={screen.colour}
            />)}
        </CanvasContainer>
    );
}

Canvas.propTypes = {
    screens: PropTypes.arrayOf(PropTypes.shape({
        length: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
        colour: PropTypes.string.isRequired
    })),
 }

export default Canvas;