import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Cookies from "js-cookie";
import { Button, Checkbox, Dialog, FormControlLabel, IconButton } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import DesktopHelp1 from '../images/desktop_help_1.png';
import DesktopHelp2 from '../images/desktop_help_2.png';
import Header from '../images/header.png';
import MobileHelp1 from '../images/mobile_help_1.png';
import MobileHelp2 from '../images/mobile_help_2.png';
import MobileHelp3 from '../images/mobile_help_3.png';
import MobileHelp4 from '../images/mobile_help_4.png';
import VisualComparisonHelp from '../images/visual_comparison_help.png';

const LogoContainer = styled.img`
    height: 26px;
`;

const SubHeaderContainer = styled.p`
    font-size: 12px;
    margin-top: 4px;
`;

const HelpImageContainer = styled.img`
    width: min(480px, calc(100%)) !important;
    margin-bottom: 32px;
`;

const arrowStyle = {
    position: 'absolute',
    zIndex: 2,
    top: '50%',
    transform: 'translateY(-50%)',
    msTransform: 'translateY(-50%)',
    padding: '5px'
}

const indicatorStyle = {
    transition: 'opacity .25s ease-in',
    opacity: 0.3,
    filter: 'alpha(opacity=30)',
    background:'#333',
    borderRadius: '50%',
    bottom: 0,
    width: '8px',
    height: '8px',
    cursor: 'pointer',
    display: 'inline-block',
    margin: '0 4px'
}

const renderArrowPrev = (clickHandler, hasPrev) => (
    hasPrev && <IconButton
        sx={{
            ...arrowStyle,
            left: 0
        }}
        onClick={clickHandler}
    >
        <ArrowBackIosNew />
    </IconButton>
);

const renderArrowNext = (clickHandler, hasNext) => (
    hasNext && <IconButton
        sx={{
            ...arrowStyle,
            right: 0
        }}
        onClick={clickHandler}
    >
        <ArrowForwardIos />
    </IconButton>
);

const renderIndicator = (onClickHandler, isSelected, index, label) => (
    <li
        style={isSelected ? { ...indicatorStyle, opacity: 0.7 } : indicatorStyle}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role='button'
        tabIndex={0}
        aria-label={`${label} ${index + 1}`}
    >
    </li>
);

const Help = ({ isDesktop, onClose }) => {
    const navigate = useNavigate();

    const handleClose = () => {
        onClose();
        navigate('/');
    }

    const skipIntroCookieName = 'SKIP_INTRO_' + (isDesktop ? 'D' : 'M');
    const [checkboxChecked, setCheckboxChecked] = useState(Cookies.get(skipIntroCookieName) === '1');

    const handleCheckboxChange = ({ target: { checked } }) => {
        Cookies.set(skipIntroCookieName, checked ? '1' : '0', { expires: 365 });
        setCheckboxChecked(checked);
    }

    const desktopItems = [
        {
            description: 'Set the specifications of each color-coded screen on the left panel.',
            image: DesktopHelp1,
            alt: 'Set display specifications'
        },
        {
            description: 'And voilà! A visual comparison of the displays is on the right.',
            image: VisualComparisonHelp,
            alt: 'Visual comparison'
        },
        {
            description: 'You can add more screens by clicking on the \'+\' button.',
            image: DesktopHelp2,
            alt: 'Add screens'
        }
    ];

    const mobileItems = [
        {
            description: 'Open the edit panel by tapping the edit icon on the top-left corner.',
            image: MobileHelp1,
            alt: 'Edit panel'
        },
        {
            description: 'Set the specifications of each color-coded screen.',
            image: MobileHelp2,
            alt: 'Set display specifications'
        },
        {
            description: 'Hit the apply button.',
            image: MobileHelp3,
            alt: 'Apply button'
        },
        {
            description: 'And voilà! A visual comparison of the displays is in the workspace.',
            image: VisualComparisonHelp,
            alt: 'Visual comparison'
        },
        {
            description: 'You can add more screens by tapping the \'+\' button in the edit panel.',
            image: MobileHelp4,
            alt: 'Add screens'
        }
    ];

    return (
        <Dialog open onClose={handleClose} PaperProps={{ sx: { display: 'block', padding: 3, textAlign: 'center' } }}>
            <LogoContainer src={Header} alt='Display Compare' />
            <SubHeaderContainer>Visual Screen Comparison Website</SubHeaderContainer>
            <Carousel
                renderArrowPrev={renderArrowPrev}
                renderArrowNext={renderArrowNext}
                renderIndicator={renderIndicator}
                showStatus={false}
                showThumbs={false}
            >
                { (isDesktop ? desktopItems : mobileItems).map(({description, image, alt}, index) => (
                    <div key={index}>
                        <p>{description}</p>
                        <HelpImageContainer src={image} alt={alt} />
                    </div>
                )) }
            </Carousel>
            <FormControlLabel
                control={<Checkbox checked={checkboxChecked} onChange={handleCheckboxChange}/>}
                label='Do not show on startup'
                sx={{ margin: '8px 16px 8px 0' }}
            /><br />
            <Button onClick={handleClose} variant='contained'>Get started</Button>
        </Dialog>
    );
}

Help.propTypes = {
	isDesktop: PropTypes.bool,
    onClose: PropTypes.func
};

Help.defaultProps = {
    onClose: () => {}
}

export default Help;