import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { List, ListItem } from '@mui/material';
import { Feed } from '@mui/icons-material';
import { doGetRequest } from '../utils/http-utils';

const SidebarContainer = styled.div`
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: thin;
    width: calc(100vw - 40px);
    color: #333;

    @media (min-width: 900px) {
        width: 220px;
    }

    &::-webkit-scrollbar {
        width: 12px;
    }
`;

const SidebarTitle = styled.h2`
    display: inline;
    font-size: 18px;
    margin-left: 10px;
`;

const StyledLink = styled(Link)`
    color: #1976d2;
    text-decoration: none;
`;

const NonStyledLink = styled(Link)`
    color: #333;
    text-decoration: none;
`;

const Sidebar = () => {
    const [infos, setInfos] = useState([]);

    useEffect(() => doGetRequest('', () => {}, ({infos}) => setInfos(infos), () => {}), []);

    return (
        <SidebarContainer>
            <NonStyledLink to={`/info`}><Feed /><SidebarTitle>Latest articles</SidebarTitle></NonStyledLink>
            <List sx={{ fontSize: '14px', marginTop: '10px' }}>
                { infos.map(({ id, title }, index) => (<ListItem>
                    <StyledLink key={index} to={`/info/${id}`}>{title}</StyledLink>
                </ListItem>)) }
                <ListItem>
                    <StyledLink key="more" to={`/info`}>+ more articles, news and reviews</StyledLink>
                </ListItem>
            </List>
        </SidebarContainer>
    );
}

export default Sidebar;