import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ArrowBack } from '@mui/icons-material';
import AdaptiveAppBar from '../AdaptiveAppBar';
import Footer from '../Footer';
import LogoIcon from '../LogoIcon';
import { doGetRequest } from '../../utils/http-utils';
import { dateFromId } from '../../utils/date-utils';

const InfoContainer = styled.div`
    padding: 12px 40px 24px;
    overflow-y: auto;
    scrollbar-width: thin;
    height: calc(100dvh - 220px);
    border-radius: 8px;
    background-color: #FFFFFFCC;
    margin: 40px;
    color: #333;

    @media (max-width: 899px) {
        margin: 72px 8px 24px;
        padding: 12px 24px 24px;
        height: calc(100dvh - 140px);
    }

    &::-webkit-scrollbar {
        width: 12px;
    }
`;

const HeadingContainer = styled.div`
    display: flex;
    align-items: center;
`;

const LoadingContainer = styled.div`
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
`;

const InfoTitle = styled.h1`
    margin-right: 20px;

    @media (max-width: 899px) {
        font-size: 24px;
    }
`;

const InfoDate = styled.h6`
    margin: 0 0 0 auto;
    font-weight: 400;
    opacity: 0.4;
    text-align: right;
`;

const ErrorMessage = styled.p`
    opacity: 0.4;
    font-size: 24px;
    font-weight: 600;
    margin: auto;
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
`;

const Info = ({ theme, isDesktop }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [error, setError] = useState(null);
    const [infosLoaded, setInfosLoaded] = useState(true);
    const [info, setInfo] = useState({});

    useEffect(() => /([0-9]+)-(.*?)/.test(id) ? doGetRequest(`/${id}`, setInfosLoaded, setInfo, setError) : setError(400), []);

	return (
        <ThemeProvider theme={theme}>
            <AdaptiveAppBar isDesktop={isDesktop} content={
                <Tooltip placement='left' title='Compare displays' arrow>
                    <IconButton onClick={ () => navigate('/') } size='large' sx={{
                        position: 'fixed',
                        top: isDesktop ? 34 : 8,
                        right: isDesktop ? 34 : 8
                    }}>
                        <LogoIcon />
                    </IconButton>
                </Tooltip>
            } />
            <InfoContainer>
                { infosLoaded
                    ? (error
                        ? <ErrorMessage>Unable to load</ErrorMessage>
                        : <>
                            <HeadingContainer>
                                <IconButton onClick={ () => navigate('/info') } sx={{ marginRight: 3 }}>
                                    <ArrowBack />
                                </IconButton>
                                { isDesktop && <InfoTitle>{info.title}</InfoTitle> }
                                <InfoDate>Published on <br/> { dateFromId(id) }</InfoDate>
                            </HeadingContainer>
                            { !isDesktop && <InfoTitle>{info.title}</InfoTitle> }
                            <div dangerouslySetInnerHTML={{__html: info.content}} />
                        </>)
                    : <LoadingContainer><CircularProgress /></LoadingContainer>
                } 
            </InfoContainer>
            <Footer />
        </ThemeProvider>
	);
}

Info.propTypes = {
    theme: PropTypes.object,
	isDesktop: PropTypes.bool
};

export default Info;