import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const Terms = () => {
    const navigate = useNavigate();
    const handleClose = () => navigate('/');

    return (
        <Dialog open onClose={handleClose}>
            <DialogTitle>Terms of Service</DialogTitle>
            <DialogContent dividers={true}>
                <h3>Acceptance of Terms</h3>
                <p>By using this website, you agree to the terms and conditions set forth below.</p>
                <h3>User Conduct</h3>
                <p>You agree to use this website only for lawful purposes and in a manner that does not infringe upon the rights of any third party, or that prohibits or restricts the use of this website by any other user.</p>
                <h3>Intellectual Property</h3>
                <p>All content on this website, including but not limited to text, graphics, logos, images, and software, is the property of the website owner and is protected by copyright, trademark, and other intellectual property laws.</p>
                <h3>Disclaimer of Warranties</h3>
                <p>This website and its content are provided on an \"as is\" and \"as available\" basis without any warranties of any kind, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
                <h3>Limitation of Liability</h3>
                <p>In no event shall the website owner or its affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use this website or its content.</p>
                <h3>Indemnification</h3>
                <p>"You agree to indemnify, defend, and hold harmless the website owner, its affiliates, officers, directors, employees, agents, and licensors from any claims, damages, expenses, and costs, including reasonable attorneys' fees, arising out of or in connection with your use of this website.</p>
                <h3>Modification and Termination</h3>
                <p>The website owner reserves the right to modify, suspend, or terminate this website or any part of it at any time without notice.</p>
                <h3>Governing Law</h3>
                <p>These terms and conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which the website owner is located.</p>
                <h3>Entire Agreement</h3>
                <p>These terms and conditions constitute the entire agreement between you and the website owner with respect to the use of this website and supersede all prior or contemporaneous communications and proposals, whether oral or written, between you and the website owner.</p>
                <h3>Contact Information</h3>
                <p>If you have any questions about these terms and conditions or this website, please contact us at support@displaycompare.com.</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{'Close'}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default Terms;