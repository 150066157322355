import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CircularProgress, IconButton, InputAdornment, Pagination, TextField, Tooltip } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Search } from '@mui/icons-material';
import AdaptiveAppBar from '../AdaptiveAppBar';
import Footer from '../Footer';
import InfoItem from './InfoItem';
import LogoIcon from '../LogoIcon';
import { doGetRequest } from '../../utils/http-utils';
import { isValidSearchText } from '../../utils/validation-utils';

const InfoListContainer = styled.div`
    padding: 12px 40px 24px;
    overflow-y: auto;
    scrollbar-width: thin;
    height: calc(100dvh - 316px);

    @media (max-width: 899px) {
        padding: 12px 8px 24px;
        height: calc(100dvh - 260px);
    }

    &::-webkit-scrollbar {
        width: 12px;
    }
`;

const LoadingContainer = styled.div`
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
`;

const ErrorMessage = styled.p`
    opacity: 0.4;
    font-size: 24px;
    font-weight: 600;
    margin: auto;
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
`;

const InfoList = ({ theme, isDesktop }) => {
    const navigate = useNavigate();
    const [searchBarText, setSearchBarText] = useState('');
    const [confirmedSearchText, setConfirmedSearchText] = useState('');
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [error, setError] = useState(null);
    const [infosLoaded, setInfosLoaded] = useState(true);
    const [infos, setInfos] = useState([]);

    const handleSetInfos = result => {
        setInfos(result.infos);
        setPageCount(result.totalPages);
    }

    useEffect(() => doGetRequest('', setInfosLoaded, handleSetInfos, setError), []);

    const handleSubmit = event => {
        event.preventDefault();
        setConfirmedSearchText(searchBarText);
        setPage(1);
        doGetRequest(`?search=${searchBarText}`, setInfosLoaded, handleSetInfos, setError);
    }

    const handleSearchBarChange = ({ target: { value } }) => {
        if (value === '' || isValidSearchText(value)) {
            setSearchBarText(value);
        }
    }

    const handlePageChange = (e, value) => {
        setPage(value);
        doGetRequest(`?search=${confirmedSearchText}&page=${value}`, setInfosLoaded, handleSetInfos, setError);
    }

	return (
        <ThemeProvider theme={theme}>
            <AdaptiveAppBar isDesktop={isDesktop} content={
                <Tooltip placement='left' title='Compare displays' arrow >
                    <IconButton onClick={() => navigate('/')} size='large' sx={{ position: 'fixed', top: isDesktop ? 34 : 8, right: isDesktop ? 34 : 8 }}>
                        <LogoIcon />
                    </IconButton>
                </Tooltip>
            } />
            <form onSubmit={handleSubmit}>
                <TextField
                    value={searchBarText} onChange={handleSearchBarChange}
                    placeholder='Search articles, news or reviews'
                    margin='normal'
                    sx={{ width: `calc(100dvw - ${isDesktop ? 80 : 36}px)`, padding: isDesktop ? '12px 40px 12px' : '72px 16px 0' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton color='primary' type='submit'>
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        ),
                        sx: { backgroundColor: '#FFFFFFAA' }
                    }}
                />
            </form>
            <InfoListContainer>
                { infosLoaded ? (error ? <ErrorMessage>Unable to load</ErrorMessage> : (infos?.length ? infos.map((info, index) => (<InfoItem
                    key={index}
                    isDesktop={isDesktop}
                    { ...info }
                />)) : <ErrorMessage>No results found</ErrorMessage>)) : <LoadingContainer><CircularProgress padding='0' /></LoadingContainer> }
            </InfoListContainer>
            <Pagination sx={{ display: 'flex', marginTop: isDesktop ? 2 : 1, justifyContent: 'center' }} count={pageCount} page={page} onChange={handlePageChange} />
            <Footer />
        </ThemeProvider>
	);
}

InfoList.propTypes = {
    theme: PropTypes.object,
	isDesktop: PropTypes.bool
};

export default InfoList;