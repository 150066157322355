import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { dateFromId } from "../../utils/date-utils";

const InfoItemContainer = styled.div`
    display: flex;
    cursor: pointer;
    margin: 8px;
    border-radius: 8px;
    border-width: thin;
    border-style: solid;
    border-color: #FFFFFFCC;
    align-items:center;
    background-color: #FFFFFFCC;

    :hover {
        border-color: #1976d2CC;
    }
`;

const InfoImage = styled.img`
    height: 120px;
    width: 160px;
    object-fit: cover;
    border-radius: 7px 0 0 7px;
    font-size: 12px;
    color: #333;
`;

const InfoDetails = styled.div`
    margin-left: 24px;
    color: #333;
    width: calc(100% - 48px);

    @media (min-width: 900px) {
        width: calc(100% - 200px);
    }
`;

const InfoTitle = styled.h2`
    margin: 16px 0 0;

    @media (min-width: 900px) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const InfoDate = styled.h6`
    margin: 4px 0;
    opacity: 0.4;
    font-weight: 400;
`;

const InfoSummary = styled.p`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const InfoItem = ({ isDesktop, id, title, summary, thumbnail }) => {
    const navigate = useNavigate();

	return (
		<InfoItemContainer onClick={ () => navigate(`/info/${id}`) }>
            { isDesktop && <InfoImage src={thumbnail} alt={title} /> }
            <InfoDetails>
                <InfoTitle>{title}</InfoTitle>
                <InfoDate>Published on { dateFromId(id) }</InfoDate>
                <InfoSummary>{summary}</InfoSummary>
            </InfoDetails>
        </InfoItemContainer>
	);
}

InfoItem.propTypes = {
	isDesktop: PropTypes.bool,
    id: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    thumbnail: PropTypes.string
};

export default InfoItem;