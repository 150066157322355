import styled from 'styled-components';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';

const AddScreenContainer = styled.div`
    display: flex;
    cursor: pointer;
    height: 64px;
    margin: 4px;
    border-radius: 8px;
    border-style: solid;
    justify-content:center;
    align-items:center;
    color: ${props => props.colour}; 
    background-color: ${props => props.colour + '64'}; 
`;

const AddScreen = ({colour, addScreen}) => {
    return (
        <AddScreenContainer data-testid='AddScreenContainer' colour={colour} onClick={addScreen(colour)}>
            <AddIcon />
        </AddScreenContainer>
    );
}

AddScreen.propTypes = {
    colour: PropTypes.string.isRequired,
    addScreen: PropTypes.func.isRequired
};

export default AddScreen;